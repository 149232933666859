<template>
  <div class="hello">
    <div class="menu">
        <div class="item">
            About
        </div>
        <div class="item">
            Resume
        </div>
        <div class="item">
            Contact
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
.menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.item {
    font-family: 'Share Tech Mono', monospace;
    font-size: 30px;
    color: rgb(158, 158, 158);
    border-bottom: 3px solid black;

}
.item:hover {
    color: white;
    cursor: pointer;
    border-bottom: 3px solid #00ff22;
}
.item-active {
    font-family: 'Share Tech Mono', monospace;
    font-size: 30px;
    color: white;
    cursor: pointer;
    border-bottom: 3px solid #00ff22;
}
@media screen and (max-width: 550px) {
    .item {
        font-size: 20px;
    }
    .container {
        padding-left: 50px;
        padding-right: 50px;
    }
    .container-header h1 {
        font-size: 25px;
    }
}
</style>
