<template>
  <div id="app">
    <navigation/>
    <Footer/>
  </div>
</template>

<script>
import Navigation from './components/static/Navigation.vue'
import Footer from './components/static/Footer.vue'

export default {
  name: 'App',
  components: {
    Navigation,
    Footer
  }  
}
</script>
<style>
body {
  background: black;
  padding: 50px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
