<template>
    <div class="app">
      <a href="https://github.com/willcunane" target="_blank"><i class="fa fa-github" aria-hidden="true"></i></a>
      <a href="https://www.linkedin.com/in/will-cunane-611b691a0/" target="_blank"><i class="fa fa-linkedin-square" aria-hidden="true"></i></a>
      <a href="https://www.linkedin.com/in/will-cunane-611b691a0/" target="_blank"><i class="fab fa-app-store"></i></a><br>
    </div>
</template>

<script>

export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.app {
  color: white;
  padding: 30px;
  font-size: 30px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
a {
  color: rgb(158, 158, 158);
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  border-bottom: 3px solid black;;
}
a:hover {
  color: white;
  cursor: pointer;
  border-bottom: 3px solid #00ff22;
}
</style>
